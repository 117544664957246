searchJS();

document.addEventListener('reloadSearch', function(event) {
	searchJS();

	// Manually reload places autocomplete because mapReady already fired on inital page load
	if (document.querySelector('.places-autocomplete')) {
		placesAutocomplete();
	}
});

function searchJS() {
	autocomplete();
}

document.addEventListener('mapReady', function(event) {
	if (document.querySelector('.places-autocomplete')) {
		placesAutocomplete();
	}
});

var homeSearch = document.querySelector('#home-search-submit');
if (homeSearch != null) {
	homeSearch.addEventListener('click', (e) => {
		e.preventDefault();
		var autocomplete = document.querySelector('.autocomplete');
		var results = autocomplete.querySelectorAll('li');
		
		if (results.length > 0) {
			results[0].click();
		} else {
			console.log('no resutls');
		}
	});
}


function placesAutocomplete() {
	var placeSearches = document.querySelectorAll('.places-autocomplete');

	placeSearches.forEach((placeSearch) => {
		var form = placeSearch.closest('form');

		var formInputs = form.querySelectorAll('input');
		formInputs.forEach((input) => {
			if (input.type === 'search') {
				input.autocomplete = 'off';
			} else if (input.type === 'text') {
				input.autocomplete = 'nope';
			}
		});
		
		// Wrap the input in an autocomplete class
		var div = document.createElement('div');
		div.classList.add('autocomplete');

		var resultList = document.createElement('ul');
		resultList.classList.add('autocomplete__results');

		placeSearch.parentNode.insertBefore(div,placeSearch);
		div.appendChild(placeSearch);
		div.appendChild(resultList);

		placeSearch.addEventListener('keydown', (e) => {
			if (e.keyCode == 13 && form.dataset.behavior != 'search') {
				e.preventDefault();
			}
		});

		placeSearch.addEventListener('keyup', () => {
			var displaySuggestions = function(predictions, status) {
				// Reset the results diplay every time
				resultList.innerHTML = '';

		    if (status != google.maps.places.PlacesServiceStatus.OK) {
		      console.log(status);
		      resultList.innerHTML = '';
					resultList.classList.remove('active');
		      return;
		    } else {
		    	if (predictions.length > 0) {
						resultList.classList.add('active');

				    predictions.forEach(function(prediction) {
				    	// Below filters result to county
					    //if (prediction.types.indexOf('administrative_area_level_2') >= 0)	{
					    	var li = document.createElement('li');
					      var text = prediction.description;
					      li.dataset.placeid = prediction.place_id;

					      var regex = new RegExp(placeSearch.value, 'gi');
				        var styledText = text.replace(regex, function(str) {
				          return "<span style='font-weight: 900;'>" + str + "</span>"
				        });
								li.innerHTML = styledText;

					      resultList.appendChild(li);
					    //}
				    });

				    var autocompleteOptions = document.querySelectorAll('.autocomplete__results li');

						autocompleteOptions.forEach((option) => {
							option.addEventListener('click', () => {
								var service = new google.maps.places.PlacesService(placeSearch);
							  var requestPlace = {
							  	placeId: option.dataset.placeid,
							  	fields: ['name', 'formatted_address', 'place_id', 'geometry', 'address_components']
							  };

								if (form.dataset.behavior === 'map-search')	{
									console.log('map-click')
									var radiusInput = document.querySelector('#query-radius');
									var latInput = document.querySelector('#query-lat');
									var lngInput = document.querySelector('#query-lng');
									var neBoundInput = document.querySelector('#query-neBound');
									var swBoundInput = document.querySelector('#query-swBound');
									var placeSearchHiddenInput = document.querySelector('input[name="q"]');

									service.getDetails(requestPlace, function(place, status) {
								    if (status === google.maps.places.PlacesServiceStatus.OK) {
								    	// get the state of the place 
								    	// the second item in the array is the state
								    	// ONLY if the item is a county
								    	// place.address_components[1].short_name
								    	var centerLat = place.geometry.location.lat();
								    	var centerLng = place.geometry.location.lng();
								    	var northEast = place.geometry.viewport.getNorthEast();
								    	var southWest = place.geometry.viewport.getSouthWest();
								      var distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(northEast,southWest);
								    	var meterPerMile = 1609.34;
								    	var distanceMiles = distanceMeters / meterPerMile;
								    	
								    	// Submit the search radius with the query
								    	var mapWrapper = document.querySelector('.map__wrapper');
								    	latInput.value = centerLat;
								    	lngInput.value = centerLng;
								    	radiusInput.value = distanceMiles;
								    	neBoundInput.value = [northEast.lat(),northEast.lng()];
								    	swBoundInput.value = [southWest.lat(),southWest.lng()];
											placeSearch.value = option.textContent;
											placeSearchHiddenInput.value = option.textContent;

											if (mapWrapper !== null) {	
												mapWrapper.dataset.lat = centerLat;
		  									mapWrapper.dataset.lng = centerLng;
		  									mapWrapper.dataset.radius = distanceMiles;
		  									mapWrapper.dataset.ne = "["+northEast.lat()+","+northEast.lng()+"]";
		  									mapWrapper.dataset.sw = "["+southWest.lat()+","+southWest.lng()+"]";
		  								}

											if (form.dataset.remote === 'true') {
												var inputs = form.querySelectorAll('input');
												var newURL = location.protocol + '//' + location.host + location.pathname + '?';
												inputs.forEach((input) => {
													if (input.value && input.value != '') {
														newURL = newURL + input.name + '=' + input.value + '&';
													}
												});

												form.dispatchEvent(new Event('submit', {bubbles: true}));
												
												window.history.replaceState(newURL,2, newURL);
											} else {
												form.submit();
											}
								    } else {
								    	console.log('places NOT ok')
								    	// If the radius is unavailable, submit the query only
								    	placeSearch.value = option.textContent;
								    	if (form.dataset.remote === 'true') {
												form.dispatchEvent(new Event('submit', {bubbles: true}));
											} else {
												form.submit();
											}
								    }
								  });
								} else if (form.dataset.behavior === 'search') {
									placeSearch.value = option.textContent;
								  form.submit();
								} else if (form.dataset.behavior === 'tag') {
									var newTagCount = document.querySelectorAll('.tag').length + 1;
									tag = {
										input: placeSearch,
										text: option.textContent,
										inputId: 'hidden-tag-new-'+newTagCount,
										inputValue: option.textContent
									}	
									createTag(tag);
								} else if (form.dataset.behavior === 'insert') {
									service.getDetails(requestPlace, function(place, status) {
										if (status === google.maps.places.PlacesServiceStatus.OK) {
											var components = place.address_components;
											var record = placeSearch.dataset.record;

											var county = components.find(o => o.types.includes("administrative_area_level_2"));
											var city = components.find(o => o.types.includes("locality"));
											var state = components.find(o => o.types.includes("administrative_area_level_1"));
											var zip = components.find(o => o.types.includes("postal_code"));

											if (placeSearch.dataset.query === 'address') {	
												var streetNumber = components.find(o => o.types.includes("street_number"));
												var street = components.find(o => o.types.includes("route"));
												var numberPlusStreet = streetNumber.long_name + ' ' + street.long_name;

												placeSearch.value = numberPlusStreet;
												document.querySelector('input[name="'+record+'[address]"]').dispatchEvent(new Event('change'));
												document.querySelector('input[name="'+record+'[county]"]').value = county.short_name;
												document.querySelector('input[name="'+record+'[county]"]').dispatchEvent(new Event('change'));

												document.querySelector('input[name="'+record+'[city]"]').value = city.short_name;
												document.querySelector('input[name="'+record+'[city]"]').dispatchEvent(new Event('change'));

												document.querySelector('input[name="'+record+'[state]"]').value = state.short_name;
												document.querySelector('input[name="'+record+'[state]"]').dispatchEvent(new Event('change'));

												document.querySelector('input[name="'+record+'[zip]"]').value = zip.short_name;
												document.querySelector('input[name="'+record+'[zip]"]').dispatchEvent(new Event('change'));
											} else if (placeSearch.dataset.query == 'region') {
												if (county != null) {
													document.querySelector('input[name="user[suggestion_county]"]').value = county.short_name;
												} else {
													document.querySelector('input[name="user[suggestion_county]"]').value = '';
												}

												if (city != null) {
													document.querySelector('input[name="user[suggestion_city]"]').value = city.short_name;
												} else {
													document.querySelector('input[name="user[suggestion_city]"]').value = '';
												}

												if (state != null) {
													document.querySelector('input[name="user[suggestion_state]"]').value = state.short_name;
												} else {
													document.querySelector('input[name="user[suggestion_state]"]').value = '';
												}

												placeSearch.value = option.textContent;
											} else {
												placeSearch.value = option.textContent;
											}
										}
									});
								}

								resultList.innerHTML = '';
								resultList.classList.remove('active');
							});
						});
				  }
		    }
		  };

			// Create a new session token.
			var sessionToken = new google.maps.places.AutocompleteSessionToken();

			// Pass the token to the autocomplete service.
			var autocompleteService = new google.maps.places.AutocompleteService();
			if (placeSearch.value && placeSearch.value != '') {
				if (placeSearch.dataset.query === 'address') {	
					autocompleteService.getPlacePredictions({
					  input: placeSearch.value,
					  sessionToken: sessionToken,
					  types: ['address'],
					  componentRestrictions: {country: "us"}
					},
					displaySuggestions);
				} else {
					autocompleteService.getPlacePredictions({
					  input: placeSearch.value,
					  sessionToken: sessionToken,
					  types: ['(regions)'],
					  componentRestrictions: {country: "us"}
					},
					displaySuggestions);
				}
			} else {
				resultList.innerHTML = '';
				resultList.classList.remove('active');
			}
		});

		/*placeSearch.addEventListener('blur', () => {
			if (form.dataset.behavior === 'insert') {	
				var autocompleteOptions = document.querySelectorAll('.autocomplete__results li');

				placeSearch.value = autocompleteOptions[0].textContent

				resultList.innerHTML = '';
				resultList.classList.remove('active');
			}
		});*/
	});
}

function autocomplete() {
	//$searchInput = document.querySelector('#property-search');
	var searchInputs = document.querySelectorAll('.autocomplete__input');

	searchInputs.forEach((input) => {	
		var formInputs = input.closest('form').querySelectorAll('input');
		formInputs.forEach((formInput) => {
			formInput.autocomplete = 'nope';
		});

		// Wrap the input in an autocomplete class
		var div = document.createElement('div');
		div.classList.add('autocomplete');

		var resultList = document.createElement('ul');
		resultList.classList.add('autocomplete__results');

		input.parentNode.insertBefore(div,input);
		div.appendChild(input);
		div.appendChild(resultList);

		input.addEventListener('keyup', function() {
			var query = input.value;
			var xhr = new XMLHttpRequest();
		  var url = input.dataset.url + query ;
		  var response;

		  console.log(url)

		  xhr.onload = function() {
		    if (xhr.status >= 200 && xhr.status < 300) { 
		    	response = xhr.response;
		    	handleResults(response, input);
		    } else {
		      console.log('Failed response');
		      response = null;
		      handleResults(response, input);
		    }
		  }

		  xhr.open('GET', url);
		  xhr.send();
		});
	});
}

function handleResults(response, input) {
	var response = JSON.parse(response);

	if (response.counties) {
		var results = response.counties;  // generalize
	} else if (response.properties) {
		var results = response.properties;
	} else if (response.articles) {
		var results = response.articles;
	}

	var autocomplete = input.closest('.autocomplete');
	var resultList = autocomplete.querySelector('.autocomplete__results');

	// Reset the results diplay every time
	resultList.innerHTML = '';
	
	if (results.length > 0) {
		resultList.classList.add('active');

		results.forEach((result) => {
			var li = document.createElement('li');
	    var text = result.preview;
	    displayMatches();

	    function displayMatches(e) {
        var regex = new RegExp(input.value, 'gi');
        var styledText = text.replace(regex, function(str) {
          return "<span style='font-weight: 900;'>" + str + "</span>"
        });
				li.innerHTML = styledText;
				li.dataset.url = result.url;
				li.dataset.recordid = result.id;
				resultList.appendChild(li); 
	    }
		});
	} else {
		resultList.classList.remove('active');
	}

	var autocompleteOptions = document.querySelectorAll('.autocomplete__results li');

	autocompleteOptions.forEach((option) => {
		option.addEventListener('click', () => {
			if (input.classList.contains('tag-input')) {
				var tag = {
					input: input,
					text: option.textContent,
					inputId: 'hidden-county-' +option.dataset.recordid, // generalize
					inputValue: option.dataset.recordid
				}
				createTag(tag);
			} else {
				window.location = option.dataset.url;
			}
		});
	});
}

var tags = document.querySelectorAll('.tag');
tags.forEach((tag) => {
	tag.addEventListener('click', (e) => {
		removeTag(tag);
	});
});

var tagInput = document.querySelectorAll('.tag-input');
tagInput.forEach((input) => {
	input.addEventListener('keydown', (e) => {
		if (e.keyCode == 13) {
			e.preventDefault();
			
			if (input.classList.contains('tag-create') && input.value != '') {
				var newTagCount = document.querySelectorAll('.tag').length + 1;
				var inputId =	'hidden-tag-new-'+newTagCount;

				tag = {
					input: input,
					text: input.value,
					inputId: inputId,
					inputValue: input.value
				}	
				createTag(tag);
			}
		}
	});
});

function createTag(tag) {
	/// THIS ONLY SUPPORTS COUNTY TAGS SO FAR//
	var tagWrapper = tag.input.closest('.tag-input__wrapper');
	var tagList = tagWrapper.querySelector('.tag-list');
	var tagValidation = tagWrapper.querySelector('.tag-validator');

	// Add tags and hidden inputs
	var tagSpan = document.createElement('span');
	tagSpan.classList = 'tag';
	tagSpan.dataset.target = '#'+tag.inputId;
	tagSpan.innerHTML = tag.text + " | X";

	var hiddenInput = document.createElement('input');
	hiddenInput.id = tag.inputId;
	hiddenInput.type = 'hidden';
	hiddenInput.name = 'counties[]'; // generalize
	hiddenInput.value = tag.inputValue;

	tagList.appendChild(tagSpan);
	tagList.appendChild(hiddenInput);

	// Reset tag input
	//tagValidation.val('valid');
	tag.input.value = "";
	tag.input.focus();


	// Init tag removal on click
  tagSpan.addEventListener('click', () => {
  	removeTag(tagSpan);
  });
}

function removeTag(tag) {
  var hiddenInput = document.querySelector(tag.dataset.target);
  var tagWrapper = tag.closest('.tag-input__wrapper');
  var validator = tagWrapper.querySelector('.tag-validator');

  tag.remove();
  hiddenInput.remove();

  /*if (!tagWrapper.find('.tag').length) {
    validator.val("");
  }*/
}