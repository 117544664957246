var form = document.getElementById('payment-form');

if (form != null) {
  // STRIPE //
  var stripeKey = document.querySelector('#stripe-key').dataset.key;
  var stripe = Stripe(stripeKey);
  var elements = stripe.elements();
  var paymentMethod;

  // PAYMENT METHODS //
  var methodToggles = document.querySelectorAll('.payment-toggle');
  methodToggles.forEach((toggle) => {
    toggle.addEventListener('click', () => {
      methodToggles.forEach((toggle) => {
        toggle.classList.remove('active');
      });
      toggle.classList.add('active');

      var processingFeeDisplay = document.querySelector('#processing-fee');
      var grandTotalDisplay = document.querySelector('#grand-total');
      var subTotal = parseFloat(document.querySelector('#subtotal').dataset.amount);
      var amountInput = document.querySelector('#payment-amount');
      var ccFeeInput = document.querySelector('#cc-fee-amount');

      if (toggle.dataset.type === 'cc') {
        paymentMethod = 'cc';

        // Do pricing calculations in cents for precision
        var feePercentage = 0.03;
        var subtotalInCents = subTotal * 100;
        var feeAmountInCents = feePercentage * subtotalInCents;
        var grandTotalInCents = subtotalInCents + feeAmountInCents;

        // Round cents to nearest integer, then convert back to dollars
        var feeAmount = Math.round(feeAmountInCents) / 100;
        var grandTotal = Math.round(grandTotalInCents) / 100;

        processingFeeDisplay.innerHTML = feeAmount.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2});
        grandTotalDisplay.innerHTML = grandTotal.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2});

        ccFeeInput.value = feeAmount;
        amountInput.value = grandTotal;

        document.querySelector('#payment-form-wrapper').style.display = 'block';
        document.querySelector('#ach-fields').style.display = 'none';
        document.querySelector('#cc-fields').style.display = 'block';
      } else if (toggle.dataset.type === 'ach') {
        paymentMethod = 'ach';
        
        var grandTotal = parseFloat(grandTotalDisplay.dataset.amount);
        processingFeeDisplay.innerHTML = '$0.00';
        grandTotalDisplay.innerHTML = grandTotal.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2});

        amountInput.value = grandTotal;
        ccFeeInput.value = 0;

        document.querySelector('#payment-form-wrapper').style.display = 'block';
        document.querySelector('#cc-fields').style.display = 'none';
        document.querySelector('#ach-fields').style.display = 'block';
      }

    });
  });


  // Custom styling can be passed to options when creating an Element.
  var style = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  // Create an instance of the card Element.
  var card = elements.create('card', {style: style});
  
  var button = form.querySelector('button');
  var errorElement = document.getElementById('card-errors');
  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#card-element');

  // Handle real-time validation errors from the card Element.
  card.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
      button.setAttribute.disabled = 'disabled';
      button.classList.add('disabled');
    } else {
      displayError.textContent = '';
      button.setAttribute.disabled = '';
      button.classList.remove('disabled');
    }
  });

  // Handle form submission.
  form.addEventListener('submit', function(event) {
    event.preventDefault();

    button.setAttribute.disabled = 'disabled';
    button.classList.add('disabled');

    var amount = form.querySelector("input[name='amount']").value;

  	var additionalData = {
	    name: form.querySelector("input[name='name']").value
	  }

    if (paymentMethod === 'cc') {
  	  stripe.createToken(card, additionalData).then(function(result) {
  	    if (result.error) {
  	      // Inform the user if there was an error.
  	      errorElement.textContent = result.error.message;
  	    } else {
  	      // Send the token to your server.
  	      stripeTokenHandler(result.token);
  	    }
  	  });
    } else if (paymentMethod === 'ach') {
      var stripeTokenInput = form.querySelector("input[name='stripeToken']");
      if (stripeTokenInput == null || stripeTokenInput.value == '') {
        errorElement.textContent = 'Please click connect your bank.'
      } else {
        form.submit();
      }
    }
  });

  // Submit the form with the token ID.
  function stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    var form = document.getElementById('payment-form');
    var stripeTokenInput = form.querySelector("input[name='stripeToken']");
    if (stripeTokenInput != null) {
      stripeTokenInput.remove();
    }

    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', token.id);
    form.appendChild(hiddenInput);

    // Submit the form
    form.submit();
  }


  // PLAID //
  var plaidKey = document.querySelector('#plaid-key').dataset.key;
  var plaidEnv = document.querySelector('#plaid-key').dataset.env;
  var handler = Plaid.create({
    clientName: 'Granger',
    // Optional, specify an array of ISO-3166-1 alpha-2 country
    // codes to initialize Link; European countries will have GDPR
    // consent panel
    countryCodes: ['US'],
    env: plaidEnv,
    // Replace with your public_key from the Dashboard
    key: plaidKey,
    product: ['auth'],
    selectAccount: true,
    userLegalName: 'John Appleseed',
    userEmailAddress: 'jappleseed@yourapp.com',
    onLoad: function() {
      // Optional, called when Link loads
    },
    onSuccess: function(public_token, metadata) {
      // Send the public_token to your app server.
      // The metadata object contains info about the institution the
      // user selected and the account ID or IDs, if the
      // Select Account view is enabled.
      console.log('success')
      console.log(metadata);
      console.log('Public Token: ' + public_token);
      console.log('Selected account ID: ' + metadata.account_id);
      
      
      var xhr = new XMLHttpRequest();

      xhr.onload = function() {
        var response = JSON.parse(xhr.response);
        if (xhr.status >= 200 && xhr.status < 300) {
          console.log('onload success');
          console.log(response.token);

          document.querySelector('#ach-bank-name').innerHTML = metadata.institution.name;
          document.querySelector('#ach-account-name').innerHTML = metadata.accounts[0].name;
          document.querySelector('#ach-account-number').innerHTML = metadata.accounts[0].mask;
          document.querySelector('#ach-info').style.display = 'block';
          document.querySelector('#link-button').classList = '';
          document.querySelector('#link-button').innerHTML = '(Change Bank)';

          var form = document.getElementById('payment-form');

          var stripeTokenInput = form.querySelector("input[name='stripeToken']");
          if (stripeTokenInput != null) {
            stripeTokenInput.remove();
          }

          errorElement.textContent = ''

          var hiddenInput = document.createElement('input');
          hiddenInput.setAttribute('type', 'hidden');
          hiddenInput.setAttribute('name', 'stripeToken');
          hiddenInput.setAttribute('value', response.token);
          form.appendChild(hiddenInput);
        }
      }

      var data = '{"public_token": "'+public_token+'", "account_id": "'+metadata.account_id+'"}';
      xhr.open('PUT', '/exchange_plaid_token');

      var token = document.querySelector('meta[name="csrf-token"]').content
      xhr.setRequestHeader('X-CSRF-Token', token)
      xhr.setRequestHeader('Content-type', 'application/json')
      xhr.send(data);
    },
    onExit: function(err, metadata) {
      // The user exited the Link flow.
      if (err != null) {
        // The user encountered a Plaid API error prior to exiting.
      }
      // metadata contains information about the institution
      // that the user selected and the most recent API request IDs.
      // Storing this information can be helpful for support.
    },
    onEvent: function(eventName, metadata) {
      // Optionally capture Link flow events, streamed through
      // this callback as your users connect an Item to Plaid.
      // For example:
      // eventName = "TRANSITION_VIEW"
      // metadata  = {
      //   link_session_id: "123-abc",
      //   mfa_type:        "questions",
      //   timestamp:       "2017-09-14T14:42:19.350Z",
      //   view_name:       "MFA",
      // }
    }
  });
  var linkButton = document.querySelector('#link-button');
  linkButton.addEventListener('click', () => {
    handler.open();
  });
}