formsJS();

document.addEventListener('reloadForms', function() {
	formsJS();
});

filterInput();

function formsJS() {
	var lineFieldsets = document.querySelectorAll('.line-fieldset');

	var currencyFormatters = document.querySelectorAll('[data-behavior="currency-format"]');
	if (currencyFormatters.length > 0) {
		currencyFormatters.forEach((input) => {
			formatCurrency(input);
			input.addEventListener('keyup', () => {
				formatCurrency(input);
			});
			input.addEventListener('keydown', () => {
				if (!input.value || input.value == '') {
					document.querySelector(input.dataset.target).value = '';
				}
			});
			input.addEventListener('change', () => {
				formatCurrency(input);
			});
		});
	}

	lineFieldsets.forEach((fieldset) =>{
		var input = fieldset.querySelector('input');
		var textarea = fieldset.querySelector('textarea');
		var select = fieldset.querySelector('select');

		if (input != null) {
			var actualInput = input;
		} else if (textarea != null) {
			var actualInput = textarea;
		} else if (select != null) {
			var actualInput = select;
		}

		if (actualInput.value && actualInput.value !== '' && actualInput.value !== 'select-default') {
			fieldset.classList.add('is-filled');
		} else {
			fieldset.classList.remove('is-filled');
		}
		
		actualInput.addEventListener('focusin', function() {
			fieldset.classList.add('is-focused');
		});

		actualInput.addEventListener('focusout', function() {
			fieldset.classList.remove('is-focused');
		});

		actualInput.addEventListener('keyup', function() {
			if (actualInput.value && actualInput.value !== '') {
				fieldset.classList.add('is-filled');
			} else {
				fieldset.classList.remove('is-filled');
			}
		});

		actualInput.addEventListener('change', function() {
			if (actualInput.value && actualInput.value !== '' && actualInput.value !== 'select-default') {
				fieldset.classList.add('is-filled');
			} else {
				fieldset.classList.remove('is-filled');
			}
		});
	});

	//VALIDATION
	var validationButtons = document.querySelectorAll('[data-behavior="btn-validate"]');
	validationButtons.forEach((button) => {
		button.addEventListener('click', function(event) {
			validate(event);
		});
	});

	var validationInputs = document.querySelectorAll('[data-behavior="validate"]');
	if (validationInputs.length > 0) {	
		validationInputs.forEach((input) => {
			input.addEventListener('keyup', function(event) {
				if (input.classList.contains('error') && input.value != '') {
					input.classList.remove('error');
					input.closest('.line-fieldset__wrapper').classList.remove('error');
				}
			});
		});
	}
	var radioValidators = document.querySelectorAll('.validate-radio');
	if (radioValidators.length > 0) {  
		var errorMessage = document.querySelector('.error-message');
		radioValidators.forEach((validator) => {
		  validator.addEventListener('click', function(event) {
		    var checkedInput = validator.querySelector('input:checked');
		    var radioWrappers = validator.querySelectorAll('.radio');
		    var checkboxWrappers = validator.querySelectorAll('.checkbox');
		    
		    if (checkedInput === null) {
		      radioWrappers.forEach((wrapper) => {
		        errorMessage.innerHTML = '';
		        wrapper.classList.remove('error');
		      });
		      checkboxWrappers.forEach((wrapper) => {
		        errorMessage.innerHTML = '';
		        wrapper.classList.remove('error');
		      });
		    }
		  });
		});
	}

	//AUTO SAVE
	var autoSaveInputs = document.querySelectorAll('[data-behavior="autosave"]');
	autoSaveInputs.forEach((input) => {
		if (input.tagName === 'TRIX-EDITOR') {
			input.addEventListener('trix-blur', function() {
				var saveStatus = document.querySelector('#save-status');
				console.log('changed')
				saveStatus.dataset.status = 'saving';
				saveStatus.innerHTML = '...saving';
				validate(event);

				if (input.classList.contains('sub-content-trigger')) {
					setSubContent(input);
				}
			});
		}	else {
			input.addEventListener('change', function() {
				var saveStatus = document.querySelector('#save-status');
				console.log('changed')
				saveStatus.dataset.status = 'saving';
				saveStatus.innerHTML = '...saving';
				validate(event);

				if (input.classList.contains('sub-content-trigger')) {
					setSubContent(input);
				}
			});
		}
	});

	if (document.querySelector('.sub-content-trigger') !== null) {
		var subContentTriggers = document.querySelectorAll('.sub-content-trigger');
	  subContentTriggers.forEach(el => {
	  	el.dispatchEvent(new Event('change', { 'bubbles': true }))
	  });
	}

	var formFieldToggles = document.querySelectorAll('.form-field-toggle');
	if (formFieldToggles.length > 0) {
		formFieldToggles.forEach((toggle) => {
			var radio = toggle.querySelector('input');
			radio.addEventListener('click', () => {
				var target = document.querySelector(toggle.dataset.target);
				var targetInput = target.querySelector('input');
				if (toggle.dataset.behavior == 'show') {
					target.classList.add('active');
				} else if (toggle.dataset.behavior == 'hide') {
					target.classList.remove('active');
					targetInput.value = '';
					targetInput.dispatchEvent(new Event('change', { 'bubbles': true }))
				}
			});
		});
	}

	var radioOthers = document.querySelectorAll('.radio-other');
	radioOthers.forEach((wrapper) => {
		var radios = wrapper.querySelectorAll('.radio');
		var otherText = wrapper.querySelector('.radio-other-text');

		radios.forEach((radio) => {	
			radio.addEventListener('click', () => {
				var defaultName = radio.querySelector('input').name;
				var checked = wrapper.querySelector('input[type="radio"]:checked');
				if (checked.value == 'other') {
					otherText.querySelector('textarea').value = '';
					otherText.classList.add('active');
				} else {
					otherText.querySelector('textarea').value = checked.value;
					otherText.classList.remove('active');
				}
			});
		});
	});


	// BID VALIDATION
	var bidButtons = document.querySelectorAll('.bid-button');
	bidButtons.forEach((button) => {
		button.addEventListener('click', (e) => {
			var bidCard = button.closest('.bid-card');
			var form = button.closest('form');
			var input = form.querySelector('.bid-input');
			var minBid = bidCard.querySelector('.bid-tag').dataset.minbid;
			var errorMessage = form.querySelector('.bid-error-message');

			if (input.value < parseInt(minBid)) {
				e.preventDefault();
				errorMessage.innerHTML = 'Please enter a bid amount equal to or higher than the minimum bid.';
			} else {
				form.dispatchEvent(new Event('submit', {bubbles: true}));
			}
		});
	});

	var bidTags = document.querySelectorAll('.bid-tag');
	bidTags.forEach((tag) => {
		tag.addEventListener('click', () => {
			var bidCard = tag.closest('.bid-card');
			var form = bidCard.querySelector('.bid-form.active');
			var input = form.querySelector('.bid-format-input');
			input.value = parseInt(tag.dataset.minbid);
			input.dispatchEvent(new Event('change', {bubbles: true}));
		});
	});

	var bidFormToggles = document.querySelectorAll('.bid-form__toggle');
	bidFormToggles.forEach((toggle) => {
		toggle.addEventListener('click', () => {
			var target = document.querySelector(toggle.dataset.target);
			var activeForm = document.querySelector('.bid-form.active');
			var activeToggle = document.querySelector('.bid-form__toggle.active');

			activeForm.classList.remove('active');
			target.classList.add('active');

			activeToggle.classList.remove('active');
			toggle.classList.add('active');
		});
	});

	// Number input formatter
	/*var el = document.querySelector('input.number-format');
	el.addEventListener('keyup', function (event) {
	  if (event.which >= 37 && event.which <= 40) return;

	  this.value = this.value.replace(/\D/g, '')
	                         .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	});*/

	// Property image upload
	var imageInput = document.querySelector('#property-image-input');
	if (imageInput !== null) {
		var browseTrigger = document.querySelector('#property-image-input-trigger');
		browseTrigger.addEventListener('click', () => {
			imageInput.click();
		});

		imageInput.addEventListener('change', () => {
			var form = imageInput.closest('form');
			browseTrigger.classList.add('disabled');
			document.querySelector('.thumb-placeholder').classList.add('active');
			form.dispatchEvent(new Event('submit', {bubbles: true}));
		});

		Sortable.create(imagePreviews, {
		  onUpdate: function (e) {
		  	var itemEl = e.item;  // dragged HTMLElement
				console.log(e.oldIndex);  // element's old index within old parent
				console.log(e.newIndex);  // element's new index within new parent

				var url = imagePreviews.dataset.url;

				var xhr = new XMLHttpRequest();

				xhr.onload = function() {
	        var response = JSON.parse(xhr.response);
	        if (xhr.status >= 200 && xhr.status < 300) {

	        }
	      }

	      var arrayParams = [];
	      var thumbs = document.querySelectorAll('.property-img-thumb');
	      thumbs.forEach((thumb) => {
	      	arrayParams.push(thumb.dataset.sortid);
	      });


				var data = '{"sort_data": '+JSON.stringify(arrayParams)+'}';
	      xhr.open('POST', url);

	      var token = document.querySelector('meta[name="csrf-token"]').content
	      xhr.setRequestHeader('X-CSRF-Token', token)
	      xhr.setRequestHeader('Content-type', 'application/json')
	      xhr.send(data);
			}
		});
	}

	// Property acreage autocalc
	var acreInputs = document.querySelectorAll('.acreage-input');
	if (acreInputs.length > 0) {
		var totalInput = document.querySelector('#total-acre-input')
		acreInputs.forEach((input) => {
			input.addEventListener('keyup', () => {
				var totalAcres = 0;
				// Total all acre inputs
				acreInputs.forEach((input) => {
					if (!input.value || input.value == '') {
						totalAcres += 0;
					} else {
						totalAcres += parseInt(input.value);
					}
				});

				// Update total input value
				totalInput.value = totalAcres;
				input.dispatchEvent(new Event('change'));
				document.querySelector('#total-acres-display').innerHTML = totalAcres
			});
		});
	}

	// Property address input toggle
	var addressMapOpener = document.querySelector('[data-target="#address-map"]');
	if (addressMapOpener) {	
		var editorAddressInput = document.querySelector('#editorAddressInput');
		var editorLatLngInput = document.querySelector('#editorLatLngInput');
		var addressInput = editorAddressInput.querySelector('input[name="property[address]"]');
		var latInput = editorLatLngInput.querySelector('input[name="property[lat]"]');
		var lngInput = editorLatLngInput.querySelector('input[name="property[lng]"]');
		var showAddressInput = document.querySelector('#show-address-input');

		if (!addressInput.value && !latInput.value && !lngInput.value) {
			editorAddressInput.style.display = 'block';
			editorLatLngInput.style.display = 'none';
			latInput.dataset.behavior = '';
			lngInput.dataset.behavior = '';
		} else if (addressInput.value) {
			editorAddressInput.style.display = 'block';
			editorLatLngInput.style.display = 'none';
			latInput.dataset.behavior = '';
			lngInput.dataset.behavior = '';
		} else if (latInput.value && lngInput.value) {
			editorLatLngInput.style.display = 'block';
			editorAddressInput.style.display = 'none';
			addressInput.dataset.behavior = '';
		}

		addressMapOpener.addEventListener('click', () => {
			editorLatLngInput.style.display = 'block';
			editorAddressInput.style.display = 'none';
			addressInput.value = '';
			addressInput.dataset.behavior = '';
		});

		showAddressInput.addEventListener('click', () => {
			editorAddressInput.style.display = 'block';
			editorLatLngInput.style.display = 'none';
			latInput.value = '';
			latInput.dataset.behavior = '';
			lngInput.value = '';
			lngInput.dataset.behavior = '';
		});
	}

	// User signup notifications
	var notificationToggle = document.querySelector('#notification-details-toggle');
	if (notificationToggle !== null) {
		console.log('')
		notificationToggle.addEventListener('change', () => {
			if (notificationToggle.checked) {
				document.querySelector('#notification-options').classList.add('active');
			} else {
				document.querySelector('#notification-options').classList.remove('active');
			}
		});
	}

	var subContentTriggers = document.querySelectorAll('.sub-content-trigger');
	subContentTriggers.forEach((trigger) => {
		setSubContent(trigger);
		trigger.addEventListener('click', () => {
			setSubContent(trigger);
		});
	});
}


function setSubContent(el) {
	var target = el.dataset.target;

	if (el.checked) {	
		console.log(target)
	  var subContent = document.querySelectorAll('.form-ui__sub-content');
	  Array.prototype.forEach.call(subContent, function(el,i) {
	    el.classList.remove('active');
	  });

	  if (target === 'all') {
	  	Array.prototype.forEach.call(subContent, function(el,i) {
		    el.classList.add('active');
		  });
	  } else {
	  	document.querySelector(target).classList.add('active');
	  }
	}
}

function filterInput() {
	var filterInputs = document.querySelectorAll('.filter-input');


	filterInputs.forEach((input) => {
		// Set defaults if params are already present in URL
		var params = (new URL(document.location)).searchParams;
    var navParams = params.get(input.name);
    var slider = input.closest('.slider');

    if (navParams !== null) {
    	if (input.type === 'checkbox') {
    		if (navParams === input.value) {
    			input.checked = 'checked';
    		}
    	} else if (slider !== null) {
    		if (input.name === 'min_acres') {
    			slider.noUiSlider.set([params.get('min_acres'),params.get('max_acres')]);
    		} else if (input.name === 'min_price') {
    			slider.noUiSlider.set([params.get('min_price'),params.get('max_price')]);
    		}
    	}
    }

		input.addEventListener('change', (event) => {
			var form = document.querySelector('#filter-form');
			form.dispatchEvent(new Event('submit', {bubbles: true}));

			document.querySelector('#property-results').innerHTML = '';
			document.querySelector('#results-loader').classList.add('active');
		});
	});
}

function validate(event) {
	event.preventDefault();
	var button = event.target;
  var valid = true;
	var form = document.querySelector(button.dataset.form);
  var validators = form.querySelectorAll('[data-behavior="validate"]');
  var radioValidators = form.querySelectorAll('.validate-radio');
  var errorMessage = document.querySelector('.error-message');
  var propertySaveStatus = document.querySelector('#save-status');

  radioValidators.forEach((validator) => {
    var checkedInput = validator.querySelector('input:checked');
    var radioWrappers = validator.querySelectorAll('.radio');
    var checkboxWrappers = validator.querySelectorAll('.checkbox');

    var subContent = validator.closest('.form-ui__sub-content');
    if (subContent !== null && !subContent.classList.contains('active')) {
  		console.log('theres a sub content here');
  	} else if (checkedInput === null) {
      valid = false;
      errorMessage.innerHTML = 'Please fill out required fields.';
      if (propertySaveStatus != null) {
        propertySaveStatus.innerHTML = '';
      }
      radioWrappers.forEach((wrapper) => {
        wrapper.classList.add('error');
      });
      checkboxWrappers.forEach((wrapper) => {
        wrapper.classList.add('error');
      });
    }
  });

  validators.forEach((validator) => {
  	if (validator.closest('.form-ui__sub-content') !== null) {
  		console.log('theres a sub content here');
  	} else if (!validator.value || validator.value === '' || validator.value === 'select-default') {
      valid = false;
      validator.classList.add('error');
      validator.closest('.line-fieldset__wrapper').classList.add('error');
      errorMessage.innerHTML = 'Please fill out required fields.';
    } else if (validator.name === 'email' || validator.type === 'email' ) {
      if( !validateEmail(validator.value) ) {
        valid = false;
        validator.classList.add('error');
        validator.closest('.line-fieldset__wrapper').classList.add('error');
        errorMessage.innerHTML = 'Please supply a valid email address.';
      } else {
      	validator.closest('.line-fieldset__wrapper').classList.remove('error');
        validator.classList.remove('error');
      }
    } else if (validator.min && validator.value < validator.min) {
      valid = false;
      validator.classList.add('error');
      validator.closest('.line-fieldset__wrapper').classList.add('error');
      errorMessage.innerHTML = 'Please input a value that is ' +validator.min+ ' or higher.';
      if (propertySaveStatus != null) {
        propertySaveStatus.innerHTML = '';
      }
    } else {
    	validator.closest('.line-fieldset__wrapper').classList.remove('error');
      validator.classList.remove('error');
    }
  })
  if (valid && form.classList.contains('validate-recaptcha')) {
  	var key = form.querySelector('.g-recaptcha').dataset.sitekey;
    grecaptcha.execute(key).then(function(token) {
      recaptchaCallback(token,form);
    });
  } else if (valid) {
  	console.log('valid');
  	if (form.dataset.remote === 'true') {
  		if (document.querySelector('.loading-cover') !== null) {
  			document.querySelector('.loading-cover').classList.add('active');
  		}
  		if (form.querySelector('input[name="nav_url"]') !== null) {
  			form.querySelector('input[name="nav_url"]').value = button.href;
  		}
  		form.dispatchEvent(new Event('submit', {bubbles: true}));
  	} else {
  		form.submit();
  	}
  } else {
  	console.log('invalid');
  }
}

function recaptchaCallback(token,form) {
  console.log(token);
  //var form = document.querySelector('#' + form_id);
  var key = form.querySelector('.g-recaptcha').dataset.sitekey;
  var tokenInput = form.querySelector('.recaptcha-token');
  tokenInput.value = token;

  if (token.length) {
    // Submit the inquiry form
    if (form.dataset.remote === 'true') {

    	if (form.dataset.conversion === 'signup') {
    		
    	}

  		form.dispatchEvent(new Event('submit', {bubbles: true}));
  	} else {
  		form.submit();
  	}
  }
}

function validateEmail(email) {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test( email );
}



function formatNumber(n) {
  // format number 1000000 to 1,234,567
  var noLeadingZero;
  if (n[0] === '$') {
  	if (n[1] == "0") {
  		noLeadingZero = n.replace("0","");
  	} else {
  		noLeadingZero = n;
  	}
  } else if (n[0] == "0") {
  	noLeadingZero = n.replace("0","");
  } else {
  	noLeadingZero = n;
  }
  return noLeadingZero.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function formatCurrency(input) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.
  
  // get input value
  var input_val = input.value;
  
  // don't validate empty input
  if (input_val === "") { return; }
  
  // original length
  var original_len = input_val.length;

  // initial caret position 
  var caret_pos = input.selectionStart;
    
  // check for decimal
  if (input_val.indexOf(".") >= 0) {

	  /// DONT ALLOW DECIMAL INPUT  
	    /*// get position of first decimal
	    // this prevents multiple decimals from
	    // being entered
	    var decimal_pos = input_val.indexOf(".");

	    // split number by decimal point
	    var left_side = input_val.substring(0, decimal_pos);
	    var right_side = input_val.substring(decimal_pos);

	    // add commas to left side of number
	    left_side = formatNumber(left_side);

	    // validate right side
	    right_side = formatNumber(right_side);
	    
	    // On blur make sure 2 numbers after decimal
	    if (blur === "blur") {
	      right_side += "00";
	    }
	    
	    // Limit decimal to only 2 digits
	    right_side = right_side.substring(0, 2);

	    // join number by .
	    input_val = "$" + left_side + "." + right_side;*/
	  /// DONT ALLOW DECIMAL INPUT 

    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = input_val.indexOf(".");

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);
    
    // On blur make sure 2 numbers after decimal
    if (blur === "blur") {
      right_side += "00";
    }
    
    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    input_val = "$" + left_side;

  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = "$" + input_val;
    
    // final formatting
    if (blur === "blur") {
      input_val += ".00";
    }
  }
  
  // send updated string to input
  input.value = input_val;
  var realInput = document.querySelector(input.dataset.target);
  if (!input_val || input_val == '') {
  	realInput.value = '';
  } else {
  	realInput.value = input_val.replace("$","").replace(/,/g,"");
  }
  console.log(realInput.value)
  

  // put caret back in the right position
  var updated_len = input_val.length;
  caret_pos = updated_len - original_len + caret_pos;
  input.setSelectionRange(caret_pos, caret_pos);
}




