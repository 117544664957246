document.addEventListener('mapReady', function(event) {
	if (document.querySelector('.map__wrapper')) {
		initMap();
	}
});

document.addEventListener('reloadMap', function(event) {
	initMap();
});


const createHTMLMapMarker = ({ OverlayView = google.maps.OverlayView,  ...args }) => {
  class HTMLMapMarker extends OverlayView {
    constructor() {
	    super();
	    this.latlng = args.latlng;
	    this.html = args.html;
	    this.setMap(args.map);
	  }

	  createDiv() {
	    this.div = document.createElement('div');
	    this.div.style.position = 'absolute';
	    if (this.html) {
	      this.div.innerHTML = this.html;

	      // Add internal event lisenters
	    	var infoWindow = this.div.querySelector('.info-window');
	  		var infoCloser = infoWindow.querySelector('.info-window__close');
	  		var url = infoWindow.dataset.url;

	  		// Navigate to marker URL
	  		infoWindow.addEventListener('click', (event) => {
	  			event.stopPropagation(); // Stop the nested click event from bubbling up to the parent
	  			window.location = url;
	  		});

	  		// Close marker
	  		infoCloser.addEventListener('click', (event) => {
	  			event.stopPropagation(); // Stop the nested click event from bubbling up to the parent
	  			infoWindow.style.display = 'none';
	  		});
	    }
	    google.maps.event.addDomListener(this.div, 'click', event => {
	      google.maps.event.trigger(this, 'click');
	    });
	  }

	  appendDivToOverlay() {
	    const panes = this.getPanes();
	    //panes.overlayLayer.appendChild(this.div);
	    panes.overlayImage.appendChild(this.div);
	    // For some reason .overlayImage works, but .overlayLater does not
	  }

	  positionDiv() {
	    const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
	    if (point) {
	      this.div.style.left = `${point.x - 20}px`;
	      this.div.style.top = `${point.y}px`;
	    }
	  }

	  draw() {
	    if (!this.div) {
		    this.createDiv();
		    this.appendDivToOverlay();   
		  }
		  this.positionDiv();
	  }

	  remove() {
	    if (this.div) {
	      this.div.parentNode.removeChild(this.div);
	      this.div = null;
	    }
	  }

	  getPosition() {
	    return this.latlng;
	  }

	  getDraggable() {
	    return false;
	  }

	}

  return new HTMLMapMarker();
};

var map;
var mapMarkers;
var updateMethod = 'search';
function initMap() {
	var mapWrapper = document.querySelector('.map__wrapper');
	var zoom = parseInt(mapWrapper.dataset.zoom);
  var markerRefs = document.querySelectorAll('.marker-ref');
  var center = {lat: parseFloat(mapWrapper.dataset.lat), lng: parseFloat(mapWrapper.dataset.lng)};
  var radius = mapWrapper.dataset.radius;
  var radiusInput = document.querySelector('#query-radius');
  var latInput = document.querySelector('#query-lat');
	var lngInput = document.querySelector('#query-lng');

	//if (typeof map === 'undefined') {
		// Define the map only if isn't set already to prevent duplicate maps on reload event
	  map = new google.maps.Map(
	    document.getElementById('map'), {
	    	zoom: zoom, 
	    	maxZoom: 16,
	    	mapTypeId: google.maps.MapTypeId.HYBRID,
	    	center: center,
	    	streetViewControl: false,
	    	fullscreenControl: false,
	    	mapTypeControl: true,
	    	scaleControl: false,
	    	styles: [
				  {
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#f5f5f5"
				      }
				    ]
				  },
				  {
				    "elementType": "labels.icon",
				    "stylers": [
				      {
				        "visibility": "off"
				      }
				    ]
				  },
				  {
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#616161"
				      }
				    ]
				  },
				  {
				    "elementType": "labels.text.stroke",
				    "stylers": [
				      {
				        "color": "#f5f5f5"
				      }
				    ]
				  },
				  {
				    "featureType": "administrative.land_parcel",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#bdbdbd"
				      }
				    ]
				  },
				  {
				    "featureType": "poi",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#eeeeee"
				      }
				    ]
				  },
				  {
				    "featureType": "poi",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#757575"
				      }
				    ]
				  },
				  {
				    "featureType": "poi.park",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#e5e5e5"
				      }
				    ]
				  },
				  {
				    "featureType": "poi.park",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#9e9e9e"
				      }
				    ]
				  },
				  {
				    "featureType": "road",
				    "elementType": "geometry.fill",
				    "stylers": [
				      {
				        "color": "#ffffff"
				      },
				      {
				      	"weight": 0.5
				      }
				    ]
				  },
				  {
				    "featureType": "road.arterial",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#757575"
				      }
				    ]
				  },
				  {
				    "featureType": "road.highway",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#dadada"
				      }
				    ]
				  },
				  {
				    "featureType": "road.highway",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#616161"
				      }
				    ]
				  },
				  {
				    "featureType": "road.local",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#9e9e9e"
				      }
				    ]
				  },
				  {
				    "featureType": "transit.line",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#e5e5e5"
				      }
				    ]
				  },
				  {
				    "featureType": "transit.station",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#eeeeee"
				      }
				    ]
				  },
				  {
				    "featureType": "water",
				    "elementType": "geometry",
				    "stylers": [
				      {
				        "color": "#c9c9c9"
				      }
				    ]
				  },
				  {
				    "featureType": "water",
				    "elementType": "labels.text.fill",
				    "stylers": [
				      {
				        "color": "#9e9e9e"
				      }
				    ]
				  }
				]
	    }
	  );

	  // Only add event listeners the first time the map is defined to prevent multiple events after reload
	  // Update filter only on browse map
		if (mapWrapper.dataset.behavior === 'map-search') {  
		  map.addListener('center_changed', function() {
		  	//updateFilter();
		  });  

		  var firstZoom = true;
		  var zoomSubmit = false;

		  map.addListener('zoom_changed', function() {
			  if (firstZoom) {
			  	// First time zoom triggered on load
			  	// Don't submit form, but enable zoom submit;
			  	zoomSubmit = true;
			  	firstZoom = false;
			  } else if (zoomSubmit) { 
			    updateFilter();
			    document.querySelector('#filter-form').dispatchEvent(new Event('submit', {bubbles: true}));
		  		zoomSubmit = false;
		  		updateMethod = 'zoom';
		  	}
		  });

		  map.addListener('start', function() {
		  	//console.log(map.getZoom());
		  });
		  map.addListener('dragend', function() {
		  	updateMethod = 'drag';
		  	updateFilter();
		    document.querySelector('#filter-form').dispatchEvent(new Event('submit', {bubbles: true}));
		  });
		} else if (mapWrapper.dataset.behavior == 'click-coordinates') {
			map.addListener('click', (mapMouseEvent) => {
				var coordsStatus = document.querySelector('#click-coords-status');
				var latInput = document.querySelector('input[name="property[lat]"]');
				var lngInput = document.querySelector('input[name="property[lng]"]');

				coordsStatus.innerHTML = mapMouseEvent.latLng.toString();
				latInput.value = mapMouseEvent.latLng.lat();
				latInput.closest('.line-fieldset').classList.add('is-filled');
				lngInput.value = mapMouseEvent.latLng.lng();
				lngInput.closest('.line-fieldset').classList.add('is-filled');

				function setMapOnAll(map) {
	        for (var i = 0; i < mapMarkers.length; i++) {
	          mapMarkers[i].setMap(map);
	        }
	      }
	      setMapOnAll(null);
				mapMarkers = [];

				var marker = new google.maps.Marker({
			    position: mapMouseEvent.latLng,
			    map: map
			  });

			  mapMarkers.push(marker)
			})
		}
	//}

  function updateFilter() {
  	mapWrapper.dataset.lat = map.getCenter().lat();
    mapWrapper.dataset.lng = map.getCenter().lng();
    latInput.value = map.getCenter().lat();
    lngInput.value = map.getCenter().lng();
    mapWrapper.dataset.zoom = map.getZoom();
    
    var bounds = map.getBounds();
		var center = map.getCenter();
		if (bounds && center) {
		  var northEast = bounds.getNorthEast();
		  var southWest = bounds.getSouthWest();
		  mapWrapper.dataset.ne = "["+northEast.lat()+","+northEast.lng()+"]";
			mapWrapper.dataset.sw = "["+southWest.lat()+","+southWest.lng()+"]";

		  // Calculate radius (in meters).
		  var distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(northEast, southWest);
		  var meterPerMile = 1609.34;
			var distanceMiles = distanceMeters / meterPerMile;
			var halfDistance = distanceMiles / 2;

			radiusInput.value = halfDistance;
			mapWrapper.dataset.radius = halfDistance;
		}
  }

  mapMarkers = [];
  mapMarkers.length = 0;

	if (markerRefs.length > 0) {
		drawMarkers();
	} else {
		var bounds = new google.maps.LatLngBounds();

	  if (mapWrapper.dataset.ne != null && mapWrapper.dataset.ne != '') {
	  	console.log('run ne')
	  	console.log(mapWrapper.dataset.ne)
	  	// Set map bounds based on search query if it's a large area (ie. a whole state)
		  var neBounds = JSON.parse(mapWrapper.dataset.ne);
			var swBounds = JSON.parse(mapWrapper.dataset.sw);

			var latLngNE = new google.maps.LatLng(parseFloat(neBounds[0]), parseFloat(neBounds[1]));
			var latLngSW = new google.maps.LatLng(parseFloat(swBounds[0]), parseFloat(swBounds[1]));
		}

		updateBounds();
	}

	


	///START DRAW MARKERS///
	document.addEventListener('reDrawMarkers', () => {
		for(i=0; i < mapMarkers.length; i++){
      mapMarkers[i].setMap(null);
    }

		mapMarkers = [];
  	mapMarkers.length = 0;
  	
		drawMarkers();
	});

	function drawMarkers() {
		var markerRefs = document.querySelectorAll('.marker-ref');

		markerRefs.forEach((ref) => {
	  	var lat = parseFloat(ref.dataset.lat);
	  	var lng = parseFloat(ref.dataset.lng);

	  	var title = ref.dataset.title;
	  	var image = ref.dataset.imgsrc;
	  	var url = ref.dataset.url;
	  	var id = ref.dataset.marker;
	  	var curBid = shortenLargeNumber(parseInt(ref.dataset.curbid),1);

	  	function shortenLargeNumber(num, digits) {
		    var units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
		        decimal;
		    for(var i=units.length-1; i>=0; i--) {
	        decimal = Math.pow(1000, i+1);
	        if(num <= -decimal || num >= decimal) {
	          return +(num / decimal).toFixed(digits) + units[i];
	        }
		    }
		    return num;
			}

			var huntingIcon = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>hunting</title><path d="M443.6,231.4H420.2A166.1,166.1,0,0,0,280.6,91.8V68.4a9.2,9.2,0,0,0-9.2-9.2H240.6a9.2,9.2,0,0,0-9.2,9.2V91.8A166.1,166.1,0,0,0,91.8,231.4H68.4a9.2,9.2,0,0,0-9.2,9.2v30.8a9.2,9.2,0,0,0,9.2,9.2H91.8A166.1,166.1,0,0,0,231.4,420.2v23.4a9.2,9.2,0,0,0,9.2,9.2h30.8a9.2,9.2,0,0,0,9.2-9.2V420.2A166.1,166.1,0,0,0,420.2,280.6h23.4a9.2,9.2,0,0,0,9.2-9.2V240.6A9.2,9.2,0,0,0,443.6,231.4Zm-163,138.8V339a9.2,9.2,0,0,0-9.2-9.2H240.6a9.2,9.2,0,0,0-9.2,9.2v31.2a116.7,116.7,0,0,1-89.6-89.6H173a9.2,9.2,0,0,0,9.2-9.2V240.6a9.2,9.2,0,0,0-9.2-9.2H141.8a116.7,116.7,0,0,1,89.6-89.6V173a9.2,9.2,0,0,0,9.2,9.2h30.8a9.2,9.2,0,0,0,9.2-9.2V141.8a116.7,116.7,0,0,1,89.6,89.6H339a9.2,9.2,0,0,0-9.2,9.2v30.8a9.2,9.2,0,0,0,9.2,9.2h31.2A116.7,116.7,0,0,1,280.6,370.2Zm0-114.2A24.6,24.6,0,1,1,256,231.4,24.6,24.6,0,0,1,280.6,256Z"/></svg>'
			var farmingIcon = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>farming</title><path d="M259.3,244.2c-93.1,1-85.1-83.4-85.1-83.4h0V88h3v72.6C192.3,160,258.4,161.8,259.3,244.2Zm4.8-169.5V0h-3V74.6c-9.4,8.5-62.3,61.1,0,124.9V512h3V199.4C326,135.8,273.8,83.4,264.1,74.7ZM174.2,235.1s-8,84.3,85.1,83.4C258.3,225.4,174.2,235.1,174.2,235.1Zm0,71.6s-8,84.4,85.1,83.4C258.3,297.1,174.2,306.7,174.2,306.7Zm0,75.5s-8,84.4,85.1,83.4C258.3,372.6,174.2,382.2,174.2,382.2Zm92.5-138c93,1,85-83.4,85-83.4h0V88h-3v72.6C333.6,160,267.5,161.8,266.7,244.2Zm0,74.3c93,.9,85-83.4,85-83.4S267.6,225.4,266.7,318.5Zm0,71.6c93,1,85-83.4,85-83.4S267.6,297.1,266.7,390.1Zm0,75.5c93,1,85-83.4,85-83.4S267.6,372.6,266.7,465.6Z"/></svg>'
			var ranchingIcon = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>ranching</title><path d="M113.9,170.6l23.9-.2v64.7s-56.2-.4-77.8-51.4a91.4,91.4,0,0,1-4.9-53A98.7,98.7,0,0,1,61,111.1,53.5,53.5,0,0,1,85.7,84.2h0a6.5,6.5,0,0,1,9.5,5.7h0a50.4,50.4,0,0,1-2.5,15.9C86.2,125.8,78.4,169.7,113.9,170.6Z"/><path d="M398.1,170.6l-23.9-.2v64.7s56.2-.4,77.8-51.4a91.4,91.4,0,0,0,4.9-53,98.7,98.7,0,0,0-5.9-19.6,53.5,53.5,0,0,0-24.7-26.9h0a6.5,6.5,0,0,0-9.5,5.7h0a50.4,50.4,0,0,0,2.5,15.9C425.8,125.8,433.6,169.7,398.1,170.6Z"/><path d="M361.9,218.1l-2.1-27.9a32.5,32.5,0,0,0-20.4-28.1c-36.3-14.3-82.9-12.3-82.9-12.3s-46.6-2-82.9,12.3a32.5,32.5,0,0,0-20.4,28.1l-2.1,27.9a140.7,140.7,0,0,0,10.3,64.8c6.6,15.4,16.7,31.2,32.1,41.2l20.3,82.7a28.5,28.5,0,0,0,27.5,21.8h30.4a28.5,28.5,0,0,0,27.5-21.8l20.3-82.7c15.4-10,25.5-25.8,32.1-41.2A140.7,140.7,0,0,0,361.9,218.1Z"/></svg>'

			var iconString = '';
			JSON.parse(ref.dataset.usage).forEach((usage) => {
				if (usage === 'hunting-fishing') {
					iconString += huntingIcon;
				} else if (usage === 'farming') {
					iconString += farmingIcon;
				} else if (usage === 'ranching') {
					iconString += ranchingIcon;
				}
			})

		  var latLng = new google.maps.LatLng(parseFloat(ref.dataset.lat), parseFloat(ref.dataset.lng));
		  var markerHTML = '<div class="marker__wrapper" id="'+id+'">'+
		  								    '<div class="info-window" data-url="'+url+'">'+
		  								    	'<div class="info-window__img">'+
		  								    		'<img src="'+image+'" class="img-fit"/>'+
		  								    	'</div>'+
		  								    	'<div class="info-window__content">'+
		  								    		title+
		  								    	'</div>'+
		  								    	'<div class="info-window__point"></div>'+
		  								    	'<div class="info-window__close"><i class="fa fa-times-circle"></i></div>'+
		  								    '</div>'+
		  								    '<div class="marker">'+iconString+'</div>'+
		  								  '</div>';

		  var marker = createHTMLMapMarker({
			  latlng: latLng,
			  map: map,
			  html: markerHTML
			});

	    mapMarkers.push(marker);
	  });

	  mapMarkers.forEach((marker) => {
	  	marker.addListener('click', function() {
	  		mapMarkers.forEach((otherMarker) => {
	  			var infoWindow = otherMarker.div.querySelector('.info-window');
	  			infoWindow.style.display = 'none';
	  		});

	  		var infoWindow = marker.div.querySelector('.info-window');
	  		var infoCloser = infoWindow.querySelector('.info-window__close');
	  		infoWindow.style.display = 'block';
	  	});
	  });

		var propertyCards = document.querySelectorAll('.property-card');
		if (propertyCards.length > 0) {	
			propertyCards.forEach((card) => {
				card.addEventListener('mouseenter', e => {
					var target = document.querySelector('#' + card.dataset.marker);
					target.querySelector('.marker').classList.add('hover');
				});
				card.addEventListener('mouseleave', e => {
					var target = document.querySelector('#' + card.dataset.marker);
					target.querySelector('.marker').classList.remove('hover');
				});
			});
		}
		
		
		if (updateMethod === 'search') {
			updateMethod = 'search';
			zoomSubmit = false;
			updateBounds();
		} else {
			zoomSubmit = true;
		}
		updateMethod = 'search';
	}
	///END DRAW MARKERS///

	///START UPDATE BOUNDS///
	function updateBounds() {
		if (mapWrapper.dataset.ne != null && mapWrapper.dataset.ne != '') {
			var neBounds = JSON.parse(mapWrapper.dataset.ne);
			var swBounds = JSON.parse(mapWrapper.dataset.sw);

			var latLngNE = new google.maps.LatLng({lat: parseFloat(neBounds[0]), lng: parseFloat(neBounds[1])});
			var latLngSW = new google.maps.LatLng({lat: parseFloat(swBounds[0]), lng: parseFloat(swBounds[1])});

			var testBound = new google.maps.LatLngBounds(latLngSW,latLngNE)
			map.fitBounds(testBound, 0);
			zoomSubmit = true;
		}
	}
}