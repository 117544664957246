// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require('packs/nav')
require('packs/map')
require('packs/search')
require('packs/payments')

// SLIDER MUST COME BEFORE FORMS JS
var noUiSlider = require('nouislider')
require('nouislider/distribute/nouislider.min.css')
var sliders = document.querySelectorAll('.slider');
Array.prototype.forEach.call(sliders, (el,i) => {
	var min = parseInt(el.dataset.min);
	var max = parseInt(el.dataset.max);
	var step = parseInt(el.dataset.step);

	noUiSlider.create(el, {
	  start: [min, max],
	  step: step,
	  connect: true,
	  range: {
	    'min': min,
	    'max': max
	  },
	  tooltips: true
	});
	el.noUiSlider.on('end', function() {
		var valueMin = el.noUiSlider.get()[0];
		var valueMax = el.noUiSlider.get()[1];
		var inputMin = el.querySelector('.slider-input__min');
		var inputMax = el.querySelector('.slider-input__max');

		inputMin.value = valueMin;
		inputMax.value = valueMax;

		inputMin.dispatchEvent(new Event('change', { 'bubbles': true }));
		inputMax.dispatchEvent(new Event('change', { 'bubbles': true }));
	});
});

require('packs/forms')

loadFlatpickr();
document.addEventListener('reloadFlatpickr', function() {
	loadFlatpickr();
});
function loadFlatpickr() {
	require('flatpickr')
	require('flatpickr/dist/flatpickr.css')
	flatpickr("[data-ui='flatpickr']", {
		altInput: true,
    altFormat: "F j, Y",
		dateFormat: "Y-m-d"
	});
	flatpickr("[data-ui='flatpickr-time']", {
		enableTime: true,
		altInput: true,
    altFormat: "F j, Y @ h:i K",
		dateFormat: "Y-m-d H:i:00"
	});
}
	

require('dragscroll')




// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")