import consumer from "./consumer"

if (document.querySelector('#user-dropdown') != null) {	
	var user_id = document.querySelector('#user-dropdown').dataset.userid;
	consumer.subscriptions.create({
			channel: "AutosaveChannel",
			user_id: user_id
		},
		{
	  connected() {
	    // Called when the subscription is ready for use on the server
	  },

	  disconnected() {
	    // Called when the subscription has been terminated by the server
	  },

	  received(data) {
	    var saveStatus = document.querySelector('#save-status');
	    
			saveStatus.dataset.status = 'saved';
			saveStatus.innerHTML = 'Property Saved';
			var navButtons = document.querySelectorAll('[data-behavior="ajax-nav"]');
			navButtons.forEach((button) => {
				button.classList.remove('disabled');
			});
	  }
	});
}