navJS();

document.addEventListener('reloadNav', function() {
  navJS();
});


function navJS() {
  dropdowns();
  ajaxNav();
  tabMenus();
  modals();

  var galleries = document.querySelectorAll('.gallery');
  var galleryItems = document.querySelectorAll('.gallery__item');

  if (galleries.length > 0 && galleryItems.length >= 1 ) {
    gallery();
  }

  if (document.querySelectorAll('.toggle-wrapper-trigger').length > 0) {
    toggleTriggers();
  }

  var flashMessages = document.querySelectorAll('.flash');
  flashMessages.forEach((flash) => {
    flash.querySelector('.flash__close').addEventListener('click', () => {
      flash.classList.remove('active');
    });
  });

  //FACEBOOK SHARING//
  var facebookShares = document.querySelectorAll('.facebook-share');
  facebookShares.forEach((share) => {  
    share.addEventListener('click', () => {
      var url = share.dataset.url;
      FB.ui({
        method: 'share',
        href: url
      }, function(response){});
    });
  });

  //FACEBOOK SHARING//
  window.fbAsyncInit = function() {
    FB.init({
      appId            : '776424646211270',
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v5.0'
    });
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  //LINKEDIN SHARING//
  /*var linkedinShares = document.querySelectorAll('.linkedin-share');
  linkedinShares.forEach((share) => {  
    share.addEventListener('click', () => {
      var url = share.dataset.url;
      window.open(url,'windowName','height=500,width=500');
    });
  });*/

  var pinterestShares = document.querySelectorAll('.pinterest-share');
  if (pinterestShares.length > 0) {  
    pinterestShares.forEach((share) => {  
      share.addEventListener('click', () => {
        PinUtils.pinOne({
          media: share.dataset.media,
          url: share.dataset.url,
          description: share.dataset.description
        });
      });
    })
  }

  var mobileMapToggle = document.querySelector('.mobile-map-toggle');
  if (mobileMapToggle != null) {
    var mobileMap = document.querySelector('.property-map');
    mobileMapToggle.addEventListener('click', function() {
      mobileMap.classList.toggle('active');

      if (mobileMap.classList.contains('active')) {
        mobileMapToggle.querySelector('span:first-child').style.display = 'none';
        mobileMapToggle.querySelector('span:last-child').style.display = 'block';
      } else {
        mobileMapToggle.querySelector('span:first-child').style.display = 'block';
        mobileMapToggle.querySelector('span:last-child').style.display = 'none';
      }
    });
  }
}

// Toggle nav
var toggle = document.querySelector(".toggle-nav");
if (toggle != null) {  
  toggle.addEventListener( "click", function(e) {
    e.preventDefault();
    (toggle.classList.contains("active") === true) ? toggle.classList.remove("active") : toggle.classList.add("active");
    document.querySelector('body').classList.toggle('menu-panel-open');
    document.querySelector('.menu-panel').classList.toggle('active');
    //document.querySelector('.fade-nav').classList.toggle('fade-nav-on');
  });
}

var homeScroll = document.querySelector('#banner-home .btn-circle');
if (homeScroll != null) {
  homeScroll.addEventListener('click', () => {
    document.querySelector('#home-content').scrollIntoView({behavior: "smooth", block: "start"});
  });
}

function dropdowns() {
  var dropdownButtons = document.querySelectorAll('.dropdown__trigger');

  Array.prototype.forEach.call(dropdownButtons, function(el,i) {
    el.addEventListener('click', function(e) {
      e.stopPropagation();
      var dropdown = el.closest('.dropdown');
      var targetOptions = dropdown.querySelector('.dropdown__options');
      var allOptions = document.querySelectorAll('.dropdown__options');
      
      if (targetOptions.classList.contains('active')) {
        targetOptions.classList.remove('active');
      } else {
        Array.prototype.forEach.call(allOptions, function(el,i) {
          el.classList.remove('active');
        });
        targetOptions.classList.add('active');
      }
    });
  });
}

document.addEventListener('click', (e) => {
  // Only close dropdowns if the element clicked is not inside a dropdown to keep them open while selecting options
  if (e.target.closest('.dropdown__options') === null) {
    var allOptions = document.querySelectorAll('.dropdown__options');
    allOptions.forEach((option) => {
      option.classList.remove('active');
    });

    var autocompleteResults = document.querySelectorAll('.autocomplete__results');
    autocompleteResults.forEach((results) => {
      results.classList.remove('active');
    });
  }
});

function gallery() {
  var galleryItems = document.querySelectorAll('.gallery__item');
  galleryItems[0].classList.add('active');

  var gallerySelectors = document.querySelectorAll('.gallery-select');
  gallerySelectors.forEach((selector) => {
    selector.addEventListener('click', () => {
      if (selector.dataset.target != null) {  
        var galleryWrapper = document.querySelector(selector.dataset.target);
      } else {
        var galleryWrapper = selector.closest('.gallery__wrapper');
      }

      galleryWrapper.classList.add('active');

      var activeItem = galleryWrapper.querySelector('.gallery__item.active');
      var targetImage = galleryWrapper.querySelector('.gallery__item[data-index="' +selector.dataset.index+ '"]');
      
      activeItem.classList.remove('active');
      targetImage.classList.add('active');
    });
  });

  var closers = document.querySelectorAll('.gallery-close');
  closers.forEach((closer) => {
    closer.addEventListener('click', () => {
      var galleryWrapper = closer.closest('.gallery__wrapper');
      galleryWrapper.classList.remove('active');
    });
  });

  var navPrev = document.querySelectorAll('.gallery-nav[data-direction="prev"]');
  navPrev.forEach((prev) => {
    prev.addEventListener('click', () => {
      var gallery = prev.closest('.gallery');
      var activeItem = gallery.querySelector('.gallery__item.active');
      
      if (activeItem.previousElementSibling != null) {
        var prevItem = activeItem.previousElementSibling;
      } else {
        var galleryItems = gallery.querySelectorAll('.gallery__item');
        var prevItem = galleryItems[galleryItems.length - 1];
      }

      activeItem.classList.remove('active');
      prevItem.classList.add('active');
    });
  });
  
  var navNext = document.querySelectorAll('.gallery-nav[data-direction="next"]');
  navNext.forEach((next) => {
    next.addEventListener('click', () => {
      var gallery = next.closest('.gallery');
      var activeItem = gallery.querySelector('.gallery__item.active');

      if (activeItem.nextElementSibling != null) {
        var nextItem = activeItem.nextElementSibling;
      } else {
        var nextItem = gallery.querySelectorAll('.gallery__item')[0];
      }

      activeItem.classList.remove('active');
      nextItem.classList.add('active');
    });
  });
}

//AJAX NAV
function ajaxNav(){  
  var ajaxNavs = document.querySelectorAll('[data-behavior="ajax-nav"]');
  var errorMessage = document.querySelector('.error-message');
  var propertySaveStatus = document.querySelector('#save-status');
  ajaxNavs.forEach((nav) => {
    nav.addEventListener('click', function(e) {
      e.preventDefault();

      var valid = true;
      var form = document.querySelector(nav.dataset.form);

      if (nav.dataset.autosave == 'validate') {
        var validators = form.querySelectorAll('.autosave-validate');
        var radioValidators = form.querySelectorAll('.autosave-validate-radio');

        radioValidators.forEach((validator) => {
          var checkedInput = validator.querySelector('input:checked');
          var radioWrappers = validator.querySelectorAll('.radio');
          var checkboxWrappers = validator.querySelectorAll('.checkbox');

          if (checkedInput === null) {
            valid = false;
            errorMessage.innerHTML = 'Please fill out required fields.';
            if (propertySaveStatus != null) {
              propertySaveStatus.innerHTML = '';
            }
            radioWrappers.forEach((wrapper) => {
              wrapper.classList.add('error');
            });
            checkboxWrappers.forEach((wrapper) => {
              wrapper.classList.add('error');
            });
          }
        });

        validators.forEach((validator) => {
          if (!validator.value || validator.value === '') {
            valid = false;
            validator.classList.add('error');
            validator.closest('.line-fieldset__wrapper').classList.add('error');
            errorMessage.innerHTML = 'Please fill out required fields.';
            if (propertySaveStatus != null) {
              propertySaveStatus.innerHTML = '';
            }
          } else if (validator.name === 'email' || validator.type === 'email' ) {
            if( !validateEmail(validator.value) ) {
              valid = false;
              validator.classList.add('error');
              validator.closest('.line-fieldset__wrapper').classList.add('error');
              errorMessage.innerHTML = 'Please supply a valid email address.';
            } else {
              validator.classList.remove('error');
              validator.closest('.line-fieldset__wrapper').classList.remove('error');
            }
          } else if (validator.min && validator.value < validator.min) {
            valid = false;
            validator.classList.add('error');
            validator.closest('.line-fieldset__wrapper').classList.add('error');
            errorMessage.innerHTML = 'Please input a value that is ' +validator.min+ ' or higher.';
            if (propertySaveStatus != null) {
              propertySaveStatus.innerHTML = '';
            }
          } else {
            validator.classList.remove('error');
            validator.closest('.line-fieldset__wrapper').classList.remove('error');
          }
        });
      }
        
      if (valid) {
        console.log('valid')
        window.history.pushState(e.target.href,2, e.target.href);

        var request = new XMLHttpRequest();

        request.open('GET', e.target.href, true);

        request.onload = function() {
          if (request.status >= 200 && request.status < 400) {
            var resp = request.responseText;

            document.querySelector('main').innerHTML = resp;
            navJS();
            var reloadForms = new CustomEvent('reloadForms');
            document.dispatchEvent(reloadForms);
            var reloadFlatpickr = new CustomEvent('reloadFlatpickr');
            document.dispatchEvent(reloadFlatpickr);
            var reloadSearch = new CustomEvent('reloadSearch');
            document.dispatchEvent(reloadSearch);
          }
        };

        if (propertySaveStatus.dataset.status != 'saving') {
          // Only navigate away after saving is complete
          request.send();
        }
      } else {
        console.log('invalid')
      }
    });
  });

  var validationInputs = document.querySelectorAll('.autosave-validate');
  if (validationInputs.length > 0) {  
    validationInputs.forEach((input) => {
      input.addEventListener('keyup', function(event) {
        if (input.classList.contains('error') && input.value != '') {
          errorMessage.innerHTML = '';
          input.classList.remove('error');
          input.closest('.line-fieldset__wrapper').classList.remove('error');
        }
      });
      input.addEventListener('change', function(event) {
        if (input.classList.contains('error') && input.value != '') {
          errorMessage.innerHTML = '';
          input.classList.remove('error');
          input.closest('.line-fieldset__wrapper').classList.remove('error');
        }
      });
    });
  }
  var radioValidators = document.querySelectorAll('.autosave-validate-radio');
  if (radioValidators.length > 0) {  
    radioValidators.forEach((validator) => {
      validator.addEventListener('click', function(event) {
        var checkedInput = validator.querySelector('input:checked');
        var radioWrappers = validator.querySelectorAll('.radio');
        var checkboxWrappers = validator.querySelectorAll('.checkbox');
        
        if (checkedInput === null) {
          radioWrappers.forEach((wrapper) => {
            errorMessage.innerHTML = '';
            wrapper.classList.remove('error');
          });
          checkboxWrappers.forEach((wrapper) => {
            errorMessage.innerHTML = '';
            wrapper.classList.remove('error');
          });
        }
      });
    });
  }
}

function toggleTriggers() {
  var taggleTriggers = document.querySelectorAll('.toggle-wrapper-trigger');
  taggleTriggers.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      document.querySelector(trigger.dataset.target).classList.toggle('active');
    });
  });
}


function tabMenus() {
  var tabMenus = document.querySelectorAll('.tab-menu');
  tabDefaultState();

  function tabDefaultState() {  
    tabMenus.forEach((menu) => {
      var params = (new URL(document.location)).searchParams;
      var navParams = params.get('nav');
      var tabLink;
      var target;
      var tabGroup = document.querySelector(menu.dataset.group);
      var tabLinks = menu.querySelectorAll('li');
      var tabWrappers = tabGroup.querySelectorAll('.tab-wrapper');

      // Set tab defaults based on URL parameters
      if (navParams !== null) {
        tabLink = menu.querySelector('li[data-target="#tab-'+navParams+'"]');
        target = document.querySelector(tabLink.dataset.target);
      } else {
        tabLink = tabLinks[0];
        target = document.querySelector(tabLink.dataset.target);
      }

      // Reset active state
      tabWrappers.forEach((wrapper) => {
        wrapper.classList.remove('active');
      });
      tabLinks.forEach((link) => {
        link.classList.remove('active');
      });

      // Set selected tab as active
      target.classList.add('active');
      tabLink.classList.add('active');
    });
  }

  tabMenus.forEach((menu) => {
    var params = (new URL(document.location)).searchParams;
    var navParams = params.get('nav');
    var tab;
    var target;
    var tabGroup = document.querySelector(menu.dataset.group);
    var tabLinks = menu.querySelectorAll('li');
    var tabWrappers = tabGroup.querySelectorAll('.tab-wrapper');

    tabLinks.forEach((tabLink) => {
      tabLink.addEventListener('click', () => {
        target = document.querySelector(tabLink.dataset.target);

        // Reset active state
        tabWrappers.forEach((wrapper) => {
          wrapper.classList.remove('active');
        });
        tabLinks.forEach((link) => {
          link.classList.remove('active');
        });

        // Set selected tab as active
        target.classList.add('active');
        tabLink.classList.add('active');
      });
    });
  });
}

function modals() {
  var modalTriggers = document.querySelectorAll('.modal-open');

  Array.prototype.forEach.call(modalTriggers, function(el,i) {
    el.addEventListener('click', function() {
      var target = document.querySelector(el.dataset.target);
    
      document.querySelector('body').classList.add('modal-open');
      target.classList.add('active');
    })
  });

  var modalClosers = document.querySelectorAll('[data-behavior="modal-close"]');

  modalClosers.forEach((closer) => {
    closer.addEventListener('click', function() {
      var activeModals = document.querySelectorAll('.modal.active');

      document.querySelector('body').classList.remove('modal-open');
      activeModals.forEach((modal) => {
        modal.classList.remove('active');
      });
    });
  });
}

var sortLinks = document.querySelectorAll('.sort-link');
if (sortLinks.length > 0) {
  tableSort();
}
function tableSort() {
  sortLinks.forEach((sortLink) => {
    var form = document.querySelector(sortLink.dataset.form);
    var columnInput = document.querySelector("input[name='column']");
    var directionInput = document.querySelector("input[name='direction']");
    
    sortLink.addEventListener('click', () => {
      var activeSortLink = document.querySelector('.sort-link.active');
      if (activeSortLink != null) {  
        activeSortLink.classList.remove('active');
      }

      if (sortLink.dataset.direction == 'asc') {
        sortLink.dataset.direction = 'desc';
      } else {
        sortLink.dataset.direction = 'asc';
      }

      sortLink.classList.add('active');
      columnInput.value = sortLink.dataset.column;
      directionInput.value = sortLink.dataset.direction;

      form.dispatchEvent(new Event('submit', {bubbles: true}));
    });
  });
}










